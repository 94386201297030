<template>
  <section class="odn-section">
    <div v-if="title" class="odn-section-title">
      <ion-icon v-if="icon" :icon="icon"></ion-icon>
      {{ title }}
    </div>
    <slot />
  </section>
</template>

<script>
import { IonIcon } from '@ionic/vue';

export default {
  components: {
    IonIcon,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.odn-section {
  position: relative;
  margin: 10px 0 16px 0;

  .odn-section-title {
    @include flex-default(flex-start);
    font-size: 1.4rem;
    padding: 0 24px;
    margin-bottom: 8px;

    ion-icon {
      margin-right: 16px;
    }
  }
}
</style>
