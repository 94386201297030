<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/projects"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.projectAppointmentDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="appointment">
      <!-- Title (Opt.) -->
      <ODNTitle
        v-if="appointment.title"
        :title="appointment.title"
        :icon="icons.calendarClearOutline"
      />

      <!-- Appointment Type -->
      <ODNSection
        :title="$t('sections.appointmentType')"
        :icon="icons.locateOutline"
      >
        <ODNBadge :color="appointment.appointmentType.color" full>
          {{ $t(`appointmentTypes.${appointment.appointmentType.name}`) }}
        </ODNBadge>
      </ODNSection>

      <!-- Date -->
      <ODNSection :title="$t('sections.date')" :icon="icons.calendarOutline">
        <ion-card>
          <ion-card-content>
            <p>
              {{
                $t('labels.startsAt', {
                  datetime: appointment.startsAt
                    ? formatDate(appointment.startsAt)
                    : $t('labels.undefined'),
                })
              }}
            </p>
            <p>
              {{
                $t('labels.endsAt', {
                  datetime: appointment.endsAt
                    ? formatDate(appointment.endsAt)
                    : $t('labels.undefined'),
                })
              }}
            </p>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Intervention -->
      <ODNSection
        :title="$t('sections.intervention')"
        :icon="icons.todayOutline"
      >
        <ion-card>
          <ion-card-content>
            <div v-if="appointment.intervention" class="odn-mab-8">
              {{
                $t('labels.intervention', {
                  date: formatDate(appointment.intervention),
                })
              }}
              <span class="odn-mal-4">
                ({{
                  $t(
                    `appointmentStatuses.${appointment.appointmentStatus.name}`
                  )
                }})
              </span>
            </div>
            <ion-button expand="block" color="primary" id="intervention-modal">
              {{ $t('buttons.interventionDate') }}
              <ion-icon :icon="icons.calendarOutline" slot="start"></ion-icon>
            </ion-button>
          </ion-card-content>
        </ion-card>
      </ODNSection>
      <ion-modal trigger="intervention-modal">
        <ion-content>
          <ion-datetime
            :value="formattedIntervention"
            :first-day-of-week="1"
            :show-default-buttons="true"
            :placeholder="$t('fields.date.placeholder')"
            :cancelText="$t('buttons.cancel')"
            :doneText="$t('buttons.validate')"
            @ionChange="onInterventionConfirm"
          ></ion-datetime>
        </ion-content>
      </ion-modal>

      <!-- User -->
      <ODNSection
        v-if="authData.userId !== appointment.user.id"
        :title="$t('sections.user')"
        :icon="icons.personOutline"
      >
        <ion-card>
          <ion-card-content>
            <p>
              {{ appointment.user.firstname }}
              {{ appointment.user.lastname }} ({{ appointment.user.email }})
            </p>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Store -->
      <ODNSection
        v-if="appointment.store && !loading"
        :title="$t('sections.store')"
        :icon="icons.storefrontOutline"
      >
        <ion-card>
          <ion-card-header>
            <ion-row class="ion-justify-content-between ion-align-items-center">
              <ion-col size="auto">
                <ion-thumbnail>
                  <img :src="appointment.store.retailer.logo" />
                </ion-thumbnail>
              </ion-col>
              <ion-col>
                <ion-card-subtitle>
                  {{ appointment.store.retailer.name }} -
                  {{ appointment.store.storeType.name }}
                </ion-card-subtitle>
                <ion-card-title>{{ appointment.store.name }}</ion-card-title>
              </ion-col>
            </ion-row>
          </ion-card-header>
          <ion-card-content>
            <ion-row>
              <ion-col>
                <p>{{ appointment.store.address }}</p>
                <p>
                  {{ appointment.store.postcode }} {{ appointment.store.city }}
                </p>
                <p>{{ appointment.store.country }}</p>
              </ion-col>
              <ion-col class="ion-text-end">
                <p v-if="appointment.store.grade">
                  <ion-icon :icon="icons.star"></ion-icon>
                  {{ $t('labels.grade') }} {{ appointment.store.grade }}
                </p>
                <p v-if="appointment.store.grade2">
                  <ion-icon :icon="icons.star"></ion-icon>
                  {{ $t('labels.grade2') }} {{ appointment.store.grade2 }}
                </p>
                <p v-if="appointment.store.grade3">
                  <ion-icon :icon="icons.star"></ion-icon>
                  {{ $t('labels.grade3') }} {{ appointment.store.grade3 }}
                </p>
                <p v-if="appointment.store.code">
                  {{
                    appointment.store.retailer.storeCode ||
                      this.$t('labels.code')
                  }}
                  {{ appointment.store.code }}
                </p>
                <p v-if="appointment.store.code2">
                  {{ this.$t('labels.code2') }}
                  {{ appointment.store.code2 }}
                </p>
                <p v-if="appointment.store.code3">
                  {{ this.$t('labels.code3') }}
                  {{ appointment.store.code3 }}
                </p>
              </ion-col>
            </ion-row>
            <div>
              <p v-if="appointment.store.email">
                <ion-icon :icon="icons.mailOutline" class="ion-margin-end" />
                <a :href="`mailto:${appointment.store.email}`">
                  {{ appointment.store.email }}
                </a>
              </p>
              <p v-if="appointment.store.phone">
                <ion-icon :icon="icons.callOutline" class="ion-margin-end" />
                <a :href="`call:${appointment.store.phone}`">
                  {{ appointment.store.phone }}
                </a>
              </p>
            </div>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Survey / Project (Opt.) -->
      <ODNSection
        v-if="appointment.survey && !loading"
        :title="$t('sections.project')"
        :icon="icons.fileTrayOutline"
      >
        <!-- Project -->
        <ion-card>
          <ion-card-header>
            <ion-row class="ion-justify-content-between ion-align-items-center">
              <ion-col size="auto">
                <ion-thumbnail>
                  <img :src="appointment.survey.project.customer.logo" />
                </ion-thumbnail>
              </ion-col>
              <ion-col>
                <ion-card-subtitle>
                  {{ appointment.survey.project.customer.name }}
                </ion-card-subtitle>
                <ion-card-title>{{
                  appointment.survey.project.name
                }}</ion-card-title>
              </ion-col>
            </ion-row>
          </ion-card-header>
          <ion-card-content>
            <ODNBadges>
              <ODNBadge :color="appointment.survey.project.projectStatus.color">
                {{
                  $t(
                    `projectStatuses.${appointment.survey.project.projectStatus.name}`
                  )
                }}
              </ODNBadge>
              <ODNBadge>
                <ion-icon
                  :icon="icons.ellipse"
                  :style="{
                    color: appointment.survey.project.hasSurveys
                      ? '#2dd36f'
                      : '#eb445a',
                  }"
                />
                {{ $t('labels.hasSurveys') }}
              </ODNBadge>
              <ODNBadge>
                <ion-icon
                  :icon="icons.ellipse"
                  :style="{
                    color: appointment.survey.project.hasTickets
                      ? '#2dd36f'
                      : '#eb445a',
                  }"
                />
                {{ $t('labels.hasTickets') }}
              </ODNBadge>
            </ODNBadges>
            <div v-html="appointment.survey.project.notes"></div>
          </ion-card-content>
        </ion-card>
        <!-- Survey -->
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ appointment.survey.name }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <div v-html="appointment.survey.notes"></div>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Ticket -->
      <ODNSection
        v-if="appointment.ticket && !loading"
        :title="$t('sections.ticket')"
        :icon="icons.ticketOutline"
      >
        <ion-card button @click="onSelectTicket(appointment.ticket)">
          <ion-card-header>
            <ion-row class="ion-justify-content-between ion-align-items-center">
              <ion-col size="auto">
                <ion-thumbnail>
                  <img :src="appointment.ticket.store.retailer.logo" />
                </ion-thumbnail>
              </ion-col>
              <ion-col>
                <ion-card-subtitle>
                  {{ appointment.ticket.store.retailer.name }} -
                  {{ appointment.ticket.store.name }}
                </ion-card-subtitle>
                <ion-card-title>
                  {{ formatDate(appointment.ticket.created) }} -
                  {{
                    getScenarioNameWithParent(appointment.ticket.scenario, '')
                  }}
                </ion-card-title>
              </ion-col>
            </ion-row>
          </ion-card-header>
          <ion-card-content>
            <ODNBadges>
              <ODNBadge :color="appointment.ticket.ticketStatus.color">
                {{
                  $t(`ticketStatuses.${appointment.ticket.ticketStatus.name}`)
                }}
              </ODNBadge>
              <ODNBadge>
                <ion-icon :icon="icons.ellipse" />
                {{
                  $t(`ticketOrigins.${appointment.ticket.ticketOrigin.name}`)
                }}
              </ODNBadge>
            </ODNBadges>
            <p>{{ appointment.ticket.contactName }}</p>
            <p>{{ appointment.ticket.contactPhone }}</p>
            <p>{{ appointment.ticket.contactEmail }}</p>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Notes -->
      <ODNSection
        v-if="appointment.notes"
        :title="$t('sections.notes')"
        :icon="icons.chatboxOutline"
      >
        <ion-card>
          <ion-card-content>
            <div v-html="appointment.notes"></div>
          </ion-card-content>
        </ion-card>
      </ODNSection>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRow,
  IonCol,
  IonThumbnail,
  IonIcon,
  IonButton,
  IonDatetime,
  IonModal,
  toastController,
} from '@ionic/vue';
import {
  locateOutline,
  calendarClearOutline,
  calendarOutline,
  personOutline,
  storefrontOutline,
  fileTrayOutline,
  chatboxOutline,
  mailOutline,
  callOutline,
  ticketOutline,
  star,
  ellipse,
  todayOutline,
} from 'ionicons/icons';

import ODNTitle from '@c/odn-title.vue';
import ODNSection from '@c/odn-section.vue';
import ODNBadges from '@c/odn-badges.vue';
import ODNBadge from '@c/odn-badge.vue';

import APIService from '@s/api.service';

export default {
  name: 'ProjectAppointmentDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRow,
    IonCol,
    IonThumbnail,
    IonIcon,
    IonButton,
    IonDatetime,
    IonModal,
    ODNTitle,
    ODNSection,
    ODNBadges,
    ODNBadge,
  },
  data() {
    return {
      loading: true,
      processing: false,
      icons: {
        locateOutline,
        calendarClearOutline,
        calendarOutline,
        personOutline,
        storefrontOutline,
        fileTrayOutline,
        chatboxOutline,
        mailOutline,
        callOutline,
        ticketOutline,
        star,
        ellipse,
        todayOutline,
      },
    };
  },
  computed: {
    ...mapState('appointments', {
      appointment: 'selectedAppointment',
    }),
    ...mapState('auth', ['authData']),
    formattedIntervention() {
      return this.appointment.intervention
        ? this.$dayjs(this.appointment.intervention)
            .tz('Europe/Paris')
            .format()
        : undefined;
    },
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    ...mapActions('appointments', ['selectAppointment']),
    ...mapActions('tickets', ['selectTicket']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async fetchData() {
      try {
        const { data } = await APIService.get(
          `/appointments/${this.$route.params.appointmentId}`
        );
        this.selectAppointment(data);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.appointment.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onInterventionConfirm(ev) {
      this.processing = true;
      try {
        await APIService.put(
          `/appointments/${this.$route.params.appointmentId}/intervention`,
          {
            intervention: ev.detail.value,
          }
        );
        const toast = await toastController.create({
          message: this.$t('messages.intervention.put.success'),
          color: 'success',
          duration: 2000,
        });
        toast.present();
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.intervention.put.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
      } finally {
        this.processing = false;
      }

      this.fetchData();
    },
    onSelectTicket(ticket) {
      this.selectTicket(ticket);
      this.$router.push(`/tabs/stores/${ticket.store.id}/tickets/${ticket.id}`);
    },
    getScenarioNameWithParent(scenario, completeScenario) {
      if (completeScenario) {
        completeScenario = scenario.name + ' > ' + completeScenario;
      } else {
        completeScenario = scenario.name;
      }
      if (scenario.parent) {
        return this.getScenarioNameWithParent(
          scenario.parent,
          completeScenario
        );
      } else {
        return completeScenario;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ion-datetime {
  height: auto;
  width: auto;

  max-width: 320px;
}

ion-modal {
  --width: 320px;
  --height: 460px;
  --border-radius: 8px;
}

ion-modal ion-datetime {
  height: 460px;
}
</style>
