<template>
  <div class="odn-title">
    <div class="odn-title-inner">
      <ion-icon :icon="icon"></ion-icon>
      {{ title }}
    </div>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';

export default {
  components: {
    IonIcon,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.odn-title {
  position: relative;
  padding: 10px;

  .odn-title-inner {
    @include flex-default;
    padding: 16px;
    font-size: 1.2rem;
    color: var(--ion-color-light-contrast);
    background-color: var(--ion-color-light);
    border-radius: 4px;

    ion-icon {
      margin-right: 16px;
    }
  }
}
</style>
